import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import {LangContext} from '../context/langContext';
import Layout from '../template/layout';
import './article-item.scss';

const ArticleItem = ({data}) => {
	const ctx = useContext(LangContext);
	const content =  data.allMarkdownRemark.edges[0].node.frontmatter;
		return (
		<div>
		{content[ctx.lang] &&
		<Layout
			title={content[`${ctx.lang}`].articleTitle}
			description={content[`${ctx.lang}`].section[0].p}
			lang={ctx.lang}
		>
			<div className="article-item">
				{content &&
					<div>
					<p>{content.author}</p>
					<p>{content[`${ctx.lang}`].date}</p>
					<h2   
					data-sal="slide-up"
					data-sal-delay="300"
					data-sal-easing="ease">{content[`${ctx.lang}`].articleTitle}</h2>
					<div>
						{content[`${ctx.lang}`].section.map((e,i) =>(
							<div key={i}>
								<p>{e.p}</p>
								<p className="subtitle">{e.subtitle}</p>
							</div>
						))}
					</div>
					</div>
				}
			</div>
		</Layout>}
		{!content[ctx.lang] &&
		<p>loading</p>}
		</div>
	);
};

export default ArticleItem;



export const query = graphql`
	query ArticleItem($slug: String) {
		allMarkdownRemark(
			filter: { 
				frontmatter: {
					id: {
						eq: $slug
					}
				}
				fields: {
					collection: {
						eq: "BlogPost"
					}
				} 
			}
		) {
		edges {
			node {
			frontmatter {
				id
				author
				fr {
				articleTitle
				date
				section {
					subtitle
					img
					p
				}
				}
				en {
				subtitle
				date
				articleTitle
				section {
					subtitle
					img
					p
				}
				}
			}
			}
		}
		}
	}`
	
	;



